import * as React from "react"

import { Container } from "../components"
import Seo from "../components/seo"
import { Feature, Pickup, Latest, AboutMe } from "../section"

const IndexPage = () => (
  <Container>
    <Seo title="メンズ美容・メンズコスメ専門ブログ" />
    <Feature />
    <Pickup />
    <Latest />
    <AboutMe />
  </Container>
)

export default IndexPage
